.koslink {
    display: inline-block;
}

.koslink:hover a:hover, .noitalic {
    font-style: regular !important;
}

.koslink:hover .m {
	/*transform: rotate(90deg);
    display: inline-block;
    transition: .2s;*/
}



.M:hover {
	
}