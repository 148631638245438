.img {
	opacity: 1;
	width: 100%;
	height: auto;
	transition: all ease-in-out .5s;
	background-size: cover;
}

.placeholder {
	display: none;
	opacity: 1;
	width: 100%;
	height: auto;
	background-color: rgb(64, 64, 64);
	transition: all ease-in-out .5s;
}

.imageContainer {
	opacity: 1;
	width: 100%;
	height: auto;
	transition: all ease-in-out .5s;
	background-size: cover;
	filter: blur(10px);
}

