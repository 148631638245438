.c4, .c3, .c3_1, .c2, .c2_1, .c2_2, .c1, .c1_1, .c1_2, .c1_3 {
	/*margin-bottom: 15px;*/
}

/* All the Grid definitions */
.c4 {
	grid-column: 1 / 5;
}

.c3 {
	grid-column: 1 / 4;
}

.c3_1 {
	grid-column: 2 / 5;
}

.c2 {
	grid-column: 1 / 3;
}

.c2_1 {
	grid-column: 2 / 4;
}

.c2_2 {
	grid-column: 3 / 5;
}

.c1 {
	grid-column: 1 / 2;
}

.c1_1 {
	grid-column: 2 / 3;
}

.c1_2 {
	grid-column: 3 / 4;
}

.c1_3 {
	grid-column: 4 / 5;
}

/* Media Queries */

@media (max-width: 850px) {
	.c4, .c3, .c3_1, .c2, .c2_1, .c2_2, .c1, .c1_1, .c1_2, .c1_3 {
		grid-column: 1 / end;
	}
}