.form {
	transition: all ease-in-out .2s;
}

.inputText {
	width: calc(100% - 210px);
	font-family: aktiv-grotesk, sans-serif;
	font-style: normal;
	font-weight: 300;
	font-size: .8em;
	padding: 5px;
	border: 3pt solid #000;
	margin-right: 20px;
}

.inputSubmit {
	width: 172px;
	font-family: aktiv-grotesk, sans-serif;
	font-style: normal;
	font-weight: 600;
	text-transform: uppercase;
	font-size: .8em;
	padding: 5px;
	border: 3pt solid #000;
	background-color: #fff;
	border-radius: 0;
}

.importantRight p {
	text-align: right !IMPORTANT;
}

.icon {
	max-width: 200px;
	margin-left: auto;
	margin-right: auto;
	display: block;
	padding-bottom: 20px;
}

@media (max-width: 700px) {
	.inputText {
		width: calc(100% - 175px);
		margin-right: 20px;
	}

	.inputSubmit {
		width: 100px;
	}
}

@media (max-width: 500px) {
	.inputText {
		width: calc(100% - 140px);
		margin-right: 20px;
	}

	.inputSubmit {
		width: 85px;
	}
}