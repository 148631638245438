.footer {
	margin-top: 80px;
	text-align: center!important;
	margin-bottom: 20px;
}

.footer p {
	text-align: center;
}

.icon {
	text-align: center;
	width: 55px;
}