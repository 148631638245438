.container {
  margin-left: auto;
  margin-right: auto;
  display: block;
  max-width: 80vw;
  /*min-height: 100vh;*/
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

@media (min-width: 1200px) {
  .container {
    max-width: 50vw;
  }
}