.logoContainer {
	text-align: right !IMPORTANT;
}

.logoContainer * {
	text-align: right !IMPORTANT;
}
.logo {
	padding: 33px 0 5px 0;
	width: 30%;
	cursor: pointer;
}

.navigation {
	transition: all ease-in-out .2s;
	margin: 0 0 25px 0;
	visibility: hidden;
}

@media (max-width: 1000px) {
	.navigation {
		margin-bottom: 20px ;
	}
}

@media (max-width: 900px) {
	.navigation {
		margin: 0 0 10px 0;
	}
}

@media (max-width: 750px) {
	.navigation {
		margin: 0 0 0 0;
	}
}

@media (max-width: 600px) {
	.navigation {
		max-height: 75px;
		margin: 0;
	}
}

@media (max-width: 500px) {
	.navigation {
		max-height: 68px;
		margin: 0;
	}
}

@media (max-width: 300px) {
	.navigation {
		max-height: 60px;
		margin: 0;
	}
}