  .a {
    color: #000;
  	font-family: aktiv-grotesk, sans-serif;
    font-weight: 900;
    text-transform: uppercase;
  }
  
  .a:hover, .a:hover *{
    font-style: italic;
  }
  
  