th, td {
  border: 3pt solid #000;
  padding-left: 7px;
  padding-right: 7px;
  text-transform: uppercase;
}

table {
  border-collapse: collapse;
  box-sizing: border-box;
  width: 100%;
  text-align: left;
}

th {
	padding: 0 7px 0 7px;
}

td {
	padding: 0 7px 0 7px;
}

.year {
	width: 1%;
	white-space: nowrap;
}